import styled from '@emotion/styled';
import React from 'react';
import {
  Line,
  LineChart,
  Legend,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import _ from 'lodash';
import moment from 'moment';
import {Card} from 'antd';
import theme from 'services/Theme';


import * as superagent from 'superagent';


const Charts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 25px;
`;

const RepoChart = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 50px;
`;


const MetricsHeader = styled.div`
  color: ${theme.colors.primary};
  font-weight: 900;
`;

export interface IMetricsProps {}

export interface IMetricsState {
  metrics: any | undefined;
}

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

export default class Metrics extends React.Component<
  IMetricsProps,
  IMetricsState
> {
  constructor(props) {
    super(props);
    this.state = { metrics: undefined };
  }

  componentWillMount() {
    superagent
      .get(`${baseUrl}/metrics`)
      .set('Content-Type', 'application/json')
      .then(response => {
        console.log('response', response);
        this.setState({ metrics: response.body });
      });
  }


  render() {
    const dateFormat = "YYYYMMDD";//20220913
    if (this.state.metrics) {
      const repoSearchData = _.sortBy(
        _.filter(this.state.metrics.rows, (row) => row.path === '/searchRepos'), row => row.date);
      // const codeSearchData = _.sortBy(_.filter(this.state.metrics.rows, (row) => row.path === '/search'), row => row.date);
      return (
        <Card title={<MetricsHeader>Usage Metrics</MetricsHeader>}
         style={{ width: '100%', marginTop: "20px" }}>
        <Charts>
          <RepoChart>
          <LineChart width={575} height={250} data={repoSearchData}>
            <XAxis  dataKey="date" tickFormatter={(date => moment(date, dateFormat).format('M/D'))} />
            <YAxis type="number" domain={['auto', 'auto']}/>
            <Legend />
            <Tooltip labelFormatter={(value) => moment(value, dateFormat).format('MM-DD-YYYY')}/> 
            <Line dot={false} dataKey="value" name="Search Repository Page Hits" />
          </LineChart>
          </RepoChart>
          {/* <CodeChart>
          <LineChart width={375} height={250} data={codeSearchData}>
            <XAxis dataKey="date" tickFormatter={(date => moment(date, dateFormat).format('M/D'))} />
            <YAxis type="number" domain={['auto', 'auto']}/>
            <Legend />
            <Tooltip labelFormatter={(value) => moment(value, dateFormat).format('MM-DD-YYYY')}/> 
            <Line dot={false} dataKey="value" name="Search Source Code Page Hits" />
          </LineChart>
          </CodeChart> */}
          </Charts>
          
      </Card>
      );
    } else return null;
  }
}
