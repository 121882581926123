import { RefinementListFilter } from 'searchkit';
import * as React from 'react';
import * as PropTypes from "prop-types";
import { Button } from 'antd';
import styled from '@emotion/styled';
import { css, cx } from 'emotion';

const omitBy = require("lodash/omitBy");
const isUndefined = require("lodash/isUndefined");

const Container = styled.div`
  position: relative;
`;

const selectToggle = css`
  position: absolute !important;
  right: 0px;
  padding: 0px !important;
  z-index: 2;
  font-size: 13px !important; 
`;


export default class CustomRefinementListFilter extends RefinementListFilter {

  render() {
    // render method copied from searchKit source code, 
    // client/searchkit/es/components/search/filters/facet-filter/FacetFilter.js
    const renderComponent = (component, props = {}, children = null) => {
      let isReactComponent = (component["prototype"] instanceof React.Component ||
          (component["prototype"] && component["prototype"].isReactComponent) ||
          typeof component === 'function');
      if (isReactComponent) {
          return React.createElement(component, props, children);
      }
      else if (React.isValidElement(component)) {
          return React.cloneElement(component, omitBy(props, isUndefined), children);
      }
      console.warn("Invalid component", component);
      return null;
    }

    const { listComponent, containerComponent, showCount, title, id, countFormatter } = this.props;
    // MRE CODE CHANGE BEGIN added prop to pass to custom panel to render as tooltip
    const tooltip = this.props.tooltip;

    const searchKitComponent = renderComponent(containerComponent, {
      title,
      className: id ? `filter--${id}` : undefined,
      disabled: !this.hasOptions(),
      tooltip: tooltip
    }, [
        renderComponent(listComponent, {
            key: "listComponent",
            items: this.getItems(),
            itemComponent: this.props.itemComponent,
            selectedItems: this.getSelectedItems(),
            toggleItem: this.toggleFilter.bind(this),
            setItems: this.setFilters.bind(this),
            docCount: this.accessor.getDocCount(),
            showCount,
            translate: this.translate,
            countFormatter
        }),
        this.renderShowMore()
    ]);

    // custom code begins here that adds the select all/deselect all button
    const items = this.getItems();
    const selectedItems = this.getSelectedItems();
    const noneSelected = selectedItems.length === 0;

    const clicked = () => {
      if(noneSelected){
        let selected = [];
        items.map((item) => {
          selected.push(item.key);
        });
        this.accessor.state = this.accessor.state.setValue(selected);
      }else {
        this.accessor.state = this.accessor.state.clear()
      }
      this.searchkit.performSearch();
    }
    
    // if none are selected, provide select all option, if one or more selected provide deselect all option
    const label = noneSelected ? 'Select All' : 'Deselect All';
    return items.length > 0 ? (
      <Container>
        <Button type="link" onClick={clicked} className={selectToggle}>{label}</Button>
        {searchKitComponent}
      </Container>
    ) : null;
  }
}
