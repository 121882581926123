import { Layout } from 'antd';
import Header from 'components/nav/Header';
import About from 'components/page/about/About';
import Home from 'components/page/home/Home';
import Metrics from 'components/page/metrics/Metrics';
import Resources from 'components/page/resources/Resources';
import SearchRepos from 'components/page/search/SearchRepos';
import { detect } from 'detect-browser';
import { css, injectGlobal } from 'emotion';
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import withTracker from './withTracker';
import { withRouter } from 'react-router-dom';


const browser = detect();
injectGlobal`
  body {
    line-height: 1.2;
  }
`;

export interface IAppProps {
}


export class App extends React.Component<IAppProps> {
  componentDidMount() {
    if (true && browser && browser.name === 'ie') {
      const message = `
      BROWSER NOT SUPPORTED

      ME Software supports the following browsers for the best experience:
      1) Chrome: https://www.google.com/chrome
      2) Edge: https://www.microsoft.com/en-us/windows/microsoft-edge
      3) Firefox: https://www.mozilla.org/en-US/firefox/
      We recommend downloading the newest version of the above browsers for the
      best experience.
      `;
      window.alert(message);
    }
  }

  render() {
    const layoutContentStyle = css`
      max-width: 900px;
      min-width: 300px;
      margin: auto;
      padding: 0;
    `;

    const searchLayoutContentStyle = css`
        max-width: 1300px;
        min-width: 300px;
        margin: auto;
        padding: 0;
   `;

    // wraps the Switch and Route components so that withRouter can be used to get pathname
    const Routes = withRouter(({ location }) => {
      const currentStyle = location.pathname === '/searchRepos' ? searchLayoutContentStyle : layoutContentStyle;

      return (
        <Layout.Content className={currentStyle}>
          <Switch>
            <Route exact path="/" component={withTracker(Home)} />
            <Route exact path="/resources" component={withTracker(Resources)} />
            <Route exact path="/about" component={withTracker(About)} />
            <Route exact path="/metrics" component={withTracker(Metrics)} />
            <Route
              exact
              path="/searchRepos"
              component={withTracker(SearchRepos)}
            />
          </Switch>
        </Layout.Content>
      );
    });

    return (
      <Router>
        <Layout style={{ background: 'white' }}>
          <Header />
          <Routes />
        </Layout>
      </Router>
    );
  }
}

export default App;
