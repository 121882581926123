import logoImage from 'resources/MES_logo.png';
import doeImage from 'resources/doe-blue.png';
import styled from '@emotion/styled';
import { Tag } from 'antd';

export class Theme {

    colors = {
        primary: '#015582',
        secondary: '#0abcfb',
        text: 'rgba(0, 0, 0, 0.65)',
        textSecondary: '#818a91',
        error: '#BE5959',
        darkGrey: '#545F66',
        red: '#BE5959',
        veryLightGrey: '#f5f5f5'
    };

    fonts = {
        sizeDefault: '13px',
        base: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
        code: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`
    };

    logo = logoImage;
    doeLogo = doeImage;

    Label = styled('div')`
        font-weight: bold;
    `;

    PageTitle = styled('div')`
        padding: 20px 0;
        font-size: 20px;
        font-weight: 900;
        color: ${this.colors.primary};
    `;

    Tag = styled(Tag)`
        margin-bottom: 7px !important;
    `;

}

export default new Theme();