import { Card, Descriptions, Tooltip } from 'antd';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { Line, LineChart, ResponsiveContainer } from 'recharts';

const badges = css`
  padding-top: 5px;
  img {
    margin-bottom: 5px;
    padding-right: 7px;
  }
`;
const card = css`
  .ant-card {
    .ant-card-head {
      border-bottom: 0px;
    }
    .ant-card-head-title {
      color: rgba(0, 0, 0, 0.65);
    }
    .ant-card-extra {
      font-size: 11px !important;
    }
    .ant-card-body {
      padding-top: 0px;
      margin-top: -10px;
    }
    .ant-descriptions-item-label {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.65);
      padding-top: 15px;
    }
    .ant-descriptions-item {
      padding-bottom: 0px;
      span {
        font-size: 13px;
        padding-right: 13px;
        i {
          padding-right: 3px;
        }
      }
    }
  }
`;

export class GithubStats extends React.PureComponent {

  renderGitHubStat = (repoUrl, stat, icon, tooltip, bold, githubPath, extraInfo) => {
    return stat ? (
      <Tooltip title={tooltip}>
        <span>
          <a
            href={`${repoUrl}/${githubPath}`}
            target="_blank"
          >
            <i className={icon} />
            {bold ? <b>{stat}</b> : stat}
            {extraInfo}
          </a>
        </span>
      </Tooltip>
    ) : null;
  }

  render() {
    const _source = this.props._source;
    const repoUrl = _source.web_address;
    const issuesClosed = this.renderGitHubStat(repoUrl, _source.issue_closed_count, "fa fa-check", "Closed Issues", false, "issues?q=is%3Aissue+is%3Aclosed");
    const issuesOpen =  this.renderGitHubStat(repoUrl, _source.issue_open_count, "fa fa-circle-o", "Open Issues", true, "issues");
    const prClosed =  this.renderGitHubStat(repoUrl, _source.pr_closed_count, "fa fa-check", "Closed Pull Requests", false, "pulls?q=is%3Apr+is%3Aclosed");
    const prOpen = this.renderGitHubStat(repoUrl, _source.pr_open_count, "fa fa-circle-o", "Open Pull Requests", true, "pulls");
    const contributor = this.renderGitHubStat(repoUrl, _source.contributor_count, "fa fa-user", "Contributors", false, "graphs/contributors");
    const forks = this.renderGitHubStat(repoUrl, _source.fork_count, "fa fa-code-fork", "Forks", false, "network/members");

    const noIssues =
      issuesClosed === null && issuesOpen === null ? (
        <div>
          <a href={`${repoUrl}/issues`} target="_blank">
            None
          </a>
        </div>
      ) : null;
    const noPRs =
      prClosed === null && prOpen === null ? (
        <div>
          <a href={`${repoUrl}/pulls`} target="_blank">
            None
          </a>
        </div>
      ) : null;

    const releaseDate = _source.release_date
      ? ` (${moment(_source.release_date).format('M/D/YYYY')})`
      : null;

    const releases = _source.release_tag ? (
      <div>
        {this.renderGitHubStat(repoUrl, _source.release_tag, "fa fa-tag", "Latest Release Tag", false, "releases", releaseDate)}
        {this.renderGitHubStat(repoUrl, _source.release_count, "fa fa-rocket", "Number of Releases", false, "releases")}
      </div>
    ) : (
      <div>
        <a href={`${repoUrl}/releases`} target="_blank">
          Not Specified
        </a>
      </div>
    );

    const activityCounts = (
      <Descriptions layout="vertical" size="small" column="3" colon={false}>
        <Descriptions.Item label="Issues">
          {issuesOpen}
          {issuesClosed}
          {noIssues}
        </Descriptions.Item>
        <Descriptions.Item label="Pull Requests">
          {prOpen}
          {prClosed}
          {noPRs}
        </Descriptions.Item>
        <Descriptions.Item label="Contributors">
          {contributor}
        </Descriptions.Item>
        <Descriptions.Item span="2" label="Releases">
          {releases}
        </Descriptions.Item>
        <Descriptions.Item label="Forks">{forks}</Descriptions.Item>
      </Descriptions>
    );

    const chartData = _source.commit_activity
      ? _source.commit_activity.map(num => {
          const obj = {
            name: `${num}`,
            val: num,
          };
          return obj;
        })
      : [];

    const badgesTags = _source.badges ? (
      <div className={badges}>{_source.badges.map((imageAndUrl, index) => {
          try {
            const json = JSON.parse(imageAndUrl)
            return (<a key={index} href={json['image_link']} target="_blank"><img src={json['image_url']}/></a>);
          } catch (e) {
            return (<img key={index} src={imageAndUrl}/>);
          }
        }
      )}</div>
    ) : null;


    const pypiDownloadDescriptions = (_source.downloads_last_day || _source.downloads_last_month || _source.downloads_last_week) ? (
    <Descriptions layout="vertical" size="small" column="1" colon={false}>
        <Descriptions.Item label="Pypi Downloads">
          
          <div>
            <Tooltip title="Downloads Last Day">
              <span>
                <a href={`https://pypistats.org/packages/${_source.repo_name}`} target="_blank">
                  <i className="fa fa-calendar-day" />
                  {_source.downloads_last_day.toLocaleString("en-US")}
                </a>
              </span>
            </Tooltip>
            <Tooltip title="Downloads Last Week">
              <span>
                <a href={`https://pypistats.org/packages/${_source.repo_name}`} target="_blank">
                  <i className="fa fa-calendar-week" />
                  {_source.downloads_last_week.toLocaleString("en-US")}
                </a>
              </span>
            </Tooltip>
            <Tooltip title="Downloads Last Month">
              <span>
                <a href={`https://pypistats.org/packages/${_source.repo_name}`} target="_blank">
                  <i className="fa fa-calendar" />
                  {_source.downloads_last_month.toLocaleString("en-US")}
                </a>
              </span>
            </Tooltip>
            
          </div>
        </Descriptions.Item>
      </Descriptions>
    ) : null;


    const actvityChart = _source.commit_activity ? (
      <Tooltip title="Past Year of Commit Activity">
        <div>
          <ResponsiveContainer width="100%" height={50}>
            <LineChart data={chartData}>
              <Line
                type="monotone"
                dataKey="val"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Tooltip>
    ) : null;


    return (
      <div className={card}>
      <Card
        bordered={false}
        size="small"
        title={
          <a href={`${repoUrl}/pulse`} target="_blank">
            Github Activity
          </a>
        }
        extra={`Updated: ${moment(_source.updated_at).fromNow()}`}
      >
        {activityCounts}
        {actvityChart}
        {pypiDownloadDescriptions}
        {badgesTags}
      </Card>
      </div>
    );
  }
}