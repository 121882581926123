import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import App from 'components/App';
import * as serviceWorker from 'services/serviceWorker';
import ReactGA from 'react-ga';

configure({
  enforceActions: 'observed',
});

// Services that will be provided to components
const services = {};

// Adding services to the global namespace for easy debugging
declare global {
  interface Window {
    App: any;
  }
}
window.App = window.App || {};
window.App.services = services;

const gaTag: string = process.env.REACT_APP_GATAG
  ? process.env.REACT_APP_GATAG
  : 'notfound';

console.log('using GA tag:', gaTag);
ReactGA.initialize(gaTag);

ReactDOM.render(
  <Provider {...services}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
