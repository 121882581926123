import styled from '@emotion/styled';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';
import theme from 'services/Theme';
import NavItem from './NavItem';

const homeUrl = process.env.REACT_APP_CLIENT_BASE_PATH || '/';

const HeaderComponent = styled.div`
  padding: 0;
  width: 100%;
  min-width: 1300px;
  display: flex;
  flex-direction: column;
`;

const LogoRow = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 4px 4px 4px;
  width: 1300px;
`;

const NavRow = styled.div`
  background: ${theme.colors.primary};
  display: flex;
  justify-content: center;
`;

const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  width: 1200px; 
  padding: 0 4px;
`;

const ContactUsLink = styled.a`
  padding-top: 7px;
  color: white;
  &:hover {
    color: #ddd;
  }
`;

const NavText = styled.span`
  font-size: 12px;
  margin-left: 5px;
`;

const LogoImg = styled.img`
  height: 40px;
`;

export interface IHeaderProps extends RouteComponentProps {
  windowWidth: any;
}

export class Header extends React.Component<IHeaderProps> {
  render() {
    const pathname = this.props.location.pathname;
    const getNavItem = (title, path) => <NavItem displayTitle={path === pathname ? '> ' + title : title} to={path} />; //href === pathname;


    const contactUsLink = (
      <ContactUsLink
        href="mailto:PRIMREHelp@groups.nrel.gov"
        title="Contact us with a question or problem about this web page."
      >
        <i className="fa fa-envelope" />
        <NavText>Contact Us</NavText>
      </ContactUsLink>
    )

    return (
      <HeaderComponent>
        <LogoRow>
          <LogoContent>
            <Link to={homeUrl}>
              <LogoImg src={theme.logo} alt="ME Software logo" />
            </Link>
            <LogoImg src={theme.doeLogo} alt="DOE Logo" />
          </LogoContent>
        </LogoRow>
        <NavRow>
          <NavContent>
            {getNavItem('Home', '/')}
            {getNavItem('Search Software', '/searchRepos')}
            <NavItem displayTitle='Register Software' url='https://github.com/MRE-Code-Hub/Marine_Energy_Software_KH/blob/main/CONTRIBUTING.md' />
            {getNavItem('Resources', '/resources')}
            {getNavItem('About', '/about')}
          </NavContent>
          {contactUsLink}
        </NavRow>
      </HeaderComponent>
    );
  }
}

export default withRouter(windowSize(Header));
