import theme from 'services/Theme';
import { css } from 'emotion';

export const SearchContainer = css`
  width: 100%;
  height: calc(100% - 16px);
  overflow-y: auto;
  .search-kit-container {
    padding: 20px;
    display: grid;
    width: 1300px;
    margin: 0 auto;
    grid-template-rows: [Row1] auto [Row2] 1fr;
    grid-template-columns: [Col1] auto [Col2] 1fr;
    background-color: #f4f4f4;
  }
  .search-box-container {
    grid-column: Col1 / span 3;
    grid-row: Row1;
    display: flex;
    position: relative;
    background-color: ${theme.colors.primary};
    padding: 10px 15px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    .sk-search-box {
      height: 42px;
      display: flex;
      align-items: center;
      background-color: white;
      flex: 1;
      > form {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 3px 0 0 3px;
        .sk-search-box__icon {
          margin: 0 0 0 5px;
          &:before {
            opacity: 0.65;
            height: 24px;
            width: 24px;
          }
        }
        .sk-search-box__text {
          flex: 1;
          border: 0 !important;
          font-size: 14px;
          padding: 11px 20px;
          color: ${theme.colors.text};
        }
        .sk-spinning-loader {
          border-left: 3px solid ${theme.colors.primary};
        }
        .sk-search-box__action {
          display: none;
        }
      }
    }
    .include-search {
      display: flex;
      position: absolute;
      font-size: 12px;
      top: 11px;
      right: 17px;
      align-items: center;
      input {
        margin: 0px 4px 0 15px;
      }
    }
  }
  .filter-panel {
    width: 260px;
    grid-column: Col1;
    grid-row: Row2;
    flex: 1;
    padding: 20px;
    background: white;
    margin: 15px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
    .filter-container {
      flex: 1;
      margin-bottom: 20px;
      font-size: 17px;
      .sk-item-list-option__text {
        font-size: 13px;
      }
      .sk-item-list-option input.sk-item-list-option__checkbox {
        margin: 6px 5px 0 1px;
        flex: 0 0 15px;
        height: 11px;
      }
    }
    .sk-input-filter {
      color: ${theme.colors.text};
    }
    
    .sk-panel {
      position: relative;
    }
    .filter--technology .sk-panel__header {
      background-color: rgba(229, 232, 236, 1);
      padding: 8px;
    }
    .filter--repo-language .sk-panel__header {
      background-color: rgba(207, 233, 248, 1);
      padding: 8px;
    }
    .filter--collection_method .sk-panel__header {
      background-color: rgba(240, 208, 239, 1);
      padding: 8px;
    }
    .filter--life_cycle .sk-panel__header {
      background-color: rgba(204, 232, 227, 1);
      padding: 8px;
    }


    /* Common styles for all filters */
    .sk-panel__header {
      padding-left: 22px;
      max-width: 130px;
    }

    /* Shared styles for the ::before pseudo-element */
    .sk-panel__header::before {
      content: '';
      position: absolute;
      left: 0;
      background-size: cover;
    }

    /* Open Source License Filter */
    .filter--open_source_license .sk-panel__header::before {
      top: 2px;
      height: 13px;
      width: 11px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32V144C576 64.5 511.5 0 432 0S288 64.5 288 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V144z"/></svg>') no-repeat;
    }
    
    /* Non-open Source License Filter */
    .filter--non_open_source_license .sk-panel__header::before {
      top: 2px;
      height: 13px;
      width: 13px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>') no-repeat;
    }

    /* TRL Filter */
    .filter--trl .sk-panel__header::before {
      top: 4px;
      height: 12px;
      width: 12px;    
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>') no-repeat;
    }

    /* Cost Filter */
    .filter--cost .sk-panel__header::before {
      top: 4px;
      height: 12px;
      width: 10px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"/></svg>') no-repeat;
    }

    .filter--technology,
    .filter--open_source_license,
    .filter--non_open_source_license,
    .filter--trl,
    .filter--license,
    .filter--repo-language,
    .filter--lastReleased,
    .filter--lastUpdated,
    .filter--released,
    .filter--fileExtension,
    .filter--repo,
    .filter--collection_method,
    .filter--life_cycle,
    .filter--author {
      .sk-panel__header {
        border-width: 0px;
        border-radius: 5px;
        
        display: inline-block;
      }
      .sk-panel__content {
        font-size: 13px;
        .sk-item-list {
          cursor: pointer;
          padding-right: 6px;
          .sk-item-list-option {
            display: flex;
            height: 24px;
          }
          .sk-item-list-option__text {
            flex: 1;
            margin-left: 6px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
          .sk-item-list-option__count {
            display: inline-block;
            /* color: #08c; */
          }
        }
        .sk-refinement-list__view-more-action {
          cursor: pointer;
        }
      }
    }
    .sk-search-box {
      height: 28px;
      > form {
        background-color: white;
        border-radius: 3px 0 0 3px;
        border: 1px solid #dcdcdc;
        .sk-search-box__icon {
          margin: 3px 3px 0px 3px;
          &:before {
            opacity: 0.65;
            height: 20px;
            width: 20px;
          }
        }
        .sk-search-box__text {
          border: 0 !important;
          font-size: 12px;
          padding-left: 0;
          color: ${theme.colors.text};
        }
        .sk-spinning-loader {
          display: none;
        }
        .sk-search-box__action {
          display: none;
        }
      }
    }
  }
  .hits-container {
    grid-column: Col2;
    grid-row: Row2;
    flex: 1;
    padding-top: 20px;
    overflow-y: auto;
    .hits-header {
      display: flex;
      .sk-hits-stats__info {
        margin: 5px;
      }
      .sort-by {
        margin: 5px;
        font-size: 14px;
      }
      .sk-select {
        select {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
    .hits-sub-header {
      .sk-selected-filters {
        display: flex;
        flex-flow: row wrap;
        .sk-selected-filters-option {
          display: flex;
          flex: 0 0 auto;
          background: #e8e8e8;
          border: none;
          font-size: 14px;
          line-height: 20px;
          border-radius: 3px;
          color: #08c;
          padding: 2px 4px;
          margin: 2px 4px 2px 0;
        }
        .sk-selected-filters-option__remove-action {
          flex: auto;
          cursor: pointer;
          font-size: 16px;
          line-height: 18px;
          font-weight: 700;
          border-left: 1px solid #ddd;
          padding: 0 10px 0 10px;
          margin-left: 10px;
        }
      }
      .reset-expand-all-container {
        display: inline-flex;
        width: 100%;
      }
      .sk-reset-filters {
        width: 100px;
        font-size: 12px;
        color: #adadad;
        cursor: pointer;
        &:hover {
          color: #868686;
        }
      }
      .ant-checkbox-wrapper {
        flex: 1;
        text-align: right;
        padding-top: 9px;
      }
    }
    .loading-container {
      width: 100%;
      margin-top: 150px;
      .spinner-container {
        margin: 0 auto;
        width: 100px;
        color: #7d7d7d;
        font-size: 18px;
      }
    }
    .sk-pagination-navigation {
      margin-top: 18px;
    }
    .sk-toggle {
      display: flex;
      align-content: center;
      text-align: center;
      border-radius: 3px;
      max-height: 30px;
      .sk-toggle-option {
        flex: 1;
        background-color: #fff;
        border: 1px solid #ccc;
        cursor: pointer;
        font-size: 13px;
        padding: 5px 10px;
        border-right: none;
        white-space: nowrap;
        &:first-child {
          flex: 4;
        }
        &:last-child {
          flex: 4;
          border-right: 1px solid #ccc;
        }
        &.is-active {
          cursor: default;
          color: white;
          background-color: #00bd70;
        }
        &:hover {
          font-weight: 600;
        }
      }
    }

    .ant-tag-has-color {  
      color: black;
  }
`;

export default SearchContainer;
