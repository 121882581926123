import styled from '@emotion/styled';
import { Typography, Row, Col } from 'antd';
import { css } from 'emotion';
import React from 'react';
import theme from 'services/Theme';
import tecplotImage from 'resources/resources_logos/tecplot.png';
import tableauImage from 'resources/resources_logos/tableau.png';
import solidworksImage from 'resources/resources_logos/solidworks.png';
import sigmaplotImage from 'resources/resources_logos/sigmaplot.png';
import rhinoImage from 'resources/resources_logos/rhino.png';
import powerBiImage from 'resources/resources_logos/powerBi.svg';
import ssiImage from 'resources/resources_logos/SSI_PRIMARY-LOGO.svg';
import swCarpentryImage from 'resources/resources_logos/sw_carpentry.svg';
import societyRseImage from 'resources/resources_logos/society_RSE.png';
import openSSFImage from 'resources/resources_logos/OpenSSF_logo.svg.png';
import bsswImage from 'resources/resources_logos/bss.png';
import linuxFoundationImage from 'resources/resources_logos/Linux_Foundation_logo.png';



const { Title } = Typography;


const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;


const titleStyle = css`
  padding-top: 25px; 
`;

const bestPracticesTitleStyle = css`
  padding-top: 45px;
  margin-bottom: 0px;
`;

type CardProps = {
  large?: boolean;
};

const Card = styled.div<CardProps>`
  border: 1px solid #dcdcdc;
  box-shadow: rgb(0 0 0 / 30%) 3px 3px 20px;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: white;
  height: ${props => props.large ? '350px' : '205px'};
`;

const SoftwareName = styled.div`
  color: ${theme.colors.primary};
  font-weight: 900;
  flex: 0 1 auto;
  padding: 15px;
`;

const Description = styled.div`
  flex: 0 0 55%;
  padding-left: 15px;
  line-height: 1.7;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
`;

const Words = styled.p`
  padding-bottom: 15px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 15px;
`;

const ResourcesRow = ({ children, ...props }) => {
  return (
    <StyledRow gutter={16} {...props}>
      {children}
    </StyledRow>
  );
};

const ResourcesCol = ({ children, ...props }) => {
  return (
    <Col span={12} {...props}>
      {children}
    </Col>
  );
};

export interface IHomeProps { }

export default class Resources extends React.Component<IHomeProps> {

  render() {

    return (
      <PageContent>
        <Title level={3} className={titleStyle}>Software Resources</Title>
        <Words>While not exclusively used for marine energy, the following software are commonly used for marine energy applications. These software may be stand-alone packages or used in conjunction with other marine energy software.</Words>
        <ResourcesRow>
          <ResourcesCol>
            <a href="https://systatsoftware.com/sigmaplot/" target="_blank">
              <Card>
                <SoftwareName>SigmaPlot</SoftwareName>
                <Content>
                  <Description>SigmaPlot is a proprietary software package for scientific graphic and data analysis. It runs on Microsoft Windows.</Description>
                  <Logo><img style={{ width: '180px' }} src={sigmaplotImage} alt="SigmaPlot Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
          <ResourcesCol>
            <a href="https://tecplot.com/" target="_blank">
              <Card>
                <SoftwareName>TecPlot</SoftwareName>
                <Content>
                  <Description>Tecplot is the name of a family of visualization & analysis software tools developed by American company Tecplot, Inc.</Description>
                  <Logo><img style={{ width: '180px' }} src={tecplotImage} alt="Tecplot Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
        </ResourcesRow>
        <ResourcesRow>
          <ResourcesCol>
            <a href="https://www.tableau.com/" target="_blank">
              <Card>
                <SoftwareName>Tableau</SoftwareName>
                <Content>
                  <Description>Tableau products query relational databases, online analytical processing cubes, cloud databases, and spreadsheets to generate graph-type data visualizations</Description>
                  <Logo><img style={{ width: '155px' }} src={tableauImage} alt="Tableau Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
          <ResourcesCol>
            <a href="https://www.microsoft.com/en-au/power-platform/products/power-bi/" target="_blank">
              <Card>
                <SoftwareName>PowerBI</SoftwareName>
                <Content>
                  <Description>Microsoft’s business intelligence product suite, giving users the ability to connect to various data sources and create interactive reports that give insight into the underlying data</Description>
                  <Logo><img style={{ width: '105px' }} src={powerBiImage} alt="PowerBI Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
        </ResourcesRow>
        <ResourcesRow>
          <ResourcesCol>
            <a href="https://www.solidworks.com/" target="_blank">
              <Card>
                <SoftwareName>SolidWorks</SoftwareName>
                <Content>
                  <Description>SolidWorks is a solid modeling computer-aided design (CAD) and computer-aided engineering (CAE) application published by Dassault Systèmes.</Description>
                  <Logo><img style={{ width: '160px' }} src={solidworksImage} alt="SolidWorks Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
          <ResourcesCol>
            <a href="https://www.rhino3d.com/" target="_blank">
              <Card>
                <SoftwareName>Rhinoceros 3D</SoftwareName>
                <Content>
                  <Description>A commercial 3D computer graphics software. Used for computer-aided design (CAD), manufacturing,  rapid prototyping, 3D printing and reverse engineering</Description>
                  <Logo><img style={{ width: '120px' }} src={rhinoImage} alt="Rhinoceros 3D Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
        </ResourcesRow>

        <Title level={3} className={bestPracticesTitleStyle}>Best Practices</Title>
        
        <ResourcesRow>
          <ResourcesCol>
            <a href="https://bssw.io/" target="_blank">
              <Card large>
                <SoftwareName>Better Scientific Software</SoftwareName>
                <Content>
                  <Description>Better Scientific Software (BSSw) provides a central hub for the community to address pressing challenges in software productivity, quality, and sustainability.</Description>
                  <Logo><img style={{ width: '140px' }} src={bsswImage} alt="BSSw Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
          <ResourcesCol>
            <a href="https://www.linuxfoundation.org/resources/open-source-guides" target="_blank">
              <Card large>
                <SoftwareName>Linux Foundation Open Source Guides</SoftwareName>
                <Content>
                  <Description>Leverage best practices for running an open source program office or starting an open source project in your organization. Developed by The Linux Foundation in partnership with the TODO Group, these resources represent the experience of our staff, projects, and members.</Description>
                  <Logo><img style={{ width: '140px' }} src={linuxFoundationImage} alt="Linux Foundation Open Source Guides Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
        </ResourcesRow>
        <ResourcesRow>
          <ResourcesCol>
            <a href="https://openssf.org/" target="_blank">
              <Card large>
                <SoftwareName>Open Source Security Foundation</SoftwareName>
                <Content>
                  <Description>Open Source Security Foundation (OpenSSF) is a community of software developers and security engineers who are working together to secure open source software for the greater public good, and developers of the OpenSSF Best Practices Badge Program.</Description>
                  <Logo><img style={{ width: '140px' }} src={openSSFImage} alt="OpenSSF Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
          <ResourcesCol>
            <a href="https://society-rse.org/" target="_blank">
              <Card large>
                <SoftwareName>Society of Research Software Engineering</SoftwareName>
                <Content>
                  <Description>The Society of Research Software Engineering mission is to establish a research environment that recognizes the vital role of software in research. We work to increase software skills across everyone in research, to promote collaboration between researchers and software experts, and to support the creation of an academic career path for Research Software Engineers.</Description>
                  <Logo><img style={{ width: '120px' }} src={societyRseImage} alt="Society of Research Software Engineering Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
        </ResourcesRow>
        <ResourcesRow>
          <ResourcesCol>
            <a href="https://software-carpentry.org/" target="_blank">
              <Card large>
                <SoftwareName>Software Carpentry</SoftwareName>
                <Content>
                  <Description>Software Carpentry has been teaching researchers the computing skills they need to get more done in less time and with less pain. All of our lesson materials are freely reusable under the Creative Commons - Attribution license.</Description>
                  <Logo><img style={{ width: '140px' }} src={swCarpentryImage} alt="Software Carpentry Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
          <ResourcesCol>
            <a href="https://www.software.ac.uk/" target="_blank">
              <Card large>
                <SoftwareName>Software Sustainability Institute</SoftwareName>
                <Content>
                  <Description>The Software Sustainability Institute is dedicated to improving software in research. They help people build better software and more sustainable research software to enable world-class research.</Description>
                  <Logo><img style={{ width: '140px' }} src={ssiImage} alt="Software Sustainability Institute Logo" /></Logo>
                </Content>
              </Card>
            </a>
          </ResourcesCol>
        </ResourcesRow>

      </PageContent>
    );
  }
}
