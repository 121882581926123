import styled from '@emotion/styled';
import React from 'react';
import theme from 'services/Theme';
import * as superagent from 'superagent';
import { css } from 'emotion';

const FeaturedSoftwareBlock = styled.div`
  display: flex;
`;

const col = css`
  margin: 7px;
  padding: 10px;
  flex: 1;
`;

const bordered = css`
  border: 1px solid #dcdcdc;
  box-shadow: rgb(0 0 0 / 30%) 3px 3px 20px;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: white;
`;

const RepoName = styled.div`
  background: ${theme.colors.primary};
  font-weight: 900;
  flex: 0 1 auto;
  padding: 15px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 15px;
  a {
    color: white;
  }
  a > i {
    padding-right: 5px;
  }
`;

const Description = styled.div`
  font-size: 13px;
  background-color: white;
  flex: 1 1 auto;
  padding: 16px;
`;

const Links = styled.div`
  flex: 0 1 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 15px;
  flex: 0 1 40px;
  justify-content: space-between;
`;

const Link = styled.a`
  font-weight: bold;
  border-radius: 4px;
  font-size: 12px;
  &:hover {
    box-shadow: 0 0 4px rgba(33, 33, 33, 0.2);
  }
`;

export interface IFeaturedSoftwareBodyProps {}

export interface IFeaturedSoftwareBodyState {
  hits: any[];
}

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

export default class FeaturedSoftwareBody extends React.Component<
  IFeaturedSoftwareBodyProps,
  IFeaturedSoftwareBodyState
> {
  constructor(props) {
    super(props);
    this.state = { hits: [] };
  }

  componentWillMount() {
    superagent
      .post(`${baseUrl}/es/repo_v2/_search`)
      .send({
        sort: [{ release_date: { order: 'desc' } }],
        size: 3,
      })
      .set('Content-Type', 'application/json')
      .then(response => {
        this.setState({ hits: response.body.hits.hits });
      });
  }

  render() {
    const github = 'https://github.com';

    if (this.state.hits.length > 0) {
      return (
        <FeaturedSoftwareBlock>
          {this.state.hits.map((hit, index) => {
            return (
              <div key={index} className={col}>
                <div className={bordered}>
                  <RepoName>
                    <a
                      href={`${github}/${hit._source.org_name}/${hit._source.repo_name}`}
                      target="_blank"
                      rel="noopener noreferrer" 
                    >
                      <i className="fa fa-cube" />
                      {hit._source.repo_name}
                      {` ${hit._source.release_tag}`}
                    </a>
                  </RepoName>
                  {hit._source.description ? (
                    <Description>{hit._source.description}</Description>
                  ) : (
                    <Description>
                      <small>(no description provided)</small>
                    </Description>
                  )}
                  <Links>
                    <Link
                      href={`${github}/${hit._source.org_name}/${hit._source.repo_name}`}
                      target="_blank"
                      rel="noopener noreferrer" 
                    >
                      Code
                    </Link>

                    {hit._source.homepage && (
                      <>
                        <Link href={hit._source.homepage} target="_blank" rel="noopener noreferrer" >
                          Documentation
                        </Link>{' '}
                        {/* <Separator /> */}
                      </>
                    )}
                  </Links>
                </div>
              </div>
            );
          })}
        </FeaturedSoftwareBlock>
      );
    } else return null;
  }
}
