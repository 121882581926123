import styled from '@emotion/styled';
import { Col, Row, Typography } from 'antd';
import { css } from 'emotion';
import React from 'react';
import theme from 'services/Theme';

const { Title } = Typography;


const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;


const titleStyle = css`
  padding-top: 24px; 
`;


const HeaderRow = styled.div`
  background: ${theme.colors.primary};
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; // Add this line */
  color: white;
`;

const termNameStyle = css`
  width: 150px;
  font-weight: bold;
  padding-right: 10px;
`;

const termDescriptionStyle = css`
  max-width: 730px; 
  white-space: normal;
`;

const termTermNameStyle = css`
  width: 150px;
  padding-left: 25px;
`;


const termRowStyle = css`
padding: 10px;
`;

export interface IHomeProps { }

export default class About extends React.Component<IHomeProps> {
  state = {
    dictionary: null,
  };

  componentDidMount() {
    fetch('https://raw.githubusercontent.com/MRE-Code-Hub/Marine_Energy_Software_KH/main/dictionary.json')
      .then(response => response.json())
      .then(data => this.setState({ dictionary: data }))
      .catch(error => console.error('Error:', error));
  }

  render() {
    const { dictionary } = this.state;

    const renderAddlRows = (term) => {
      if (term.terms) {
        return term.terms.map((row, index) => (
          <Row type="flex" justify="start" className={termRowStyle}>
            <Col className={termTermNameStyle} >{row.name}</Col>
            <Col className={termDescriptionStyle}>{row.description}</Col>
          </Row>
        ));
      }
    }

    if (!dictionary) {
      return <div>Loading...</div>;
    }
    return (
      <PageContent>
        <Title level={4} className={titleStyle}>Goal of Marine Energy Software</Title>
        <p>Marine Energy Software is a <a href="https://openei.org/wiki/PRIMRE">PRIMRE</a> knowledge hub that serves as a public resource for software relevant to marine energy applications, including both commercial and open source packages.</p>
        <Title level={4} className={titleStyle}>Data Schema Glossary</Title>
        <div>
          {dictionary.map((item, index) => (
            <div key={index} style={{ marginBottom: '40px' }}>
              <HeaderRow>
                <Row type="flex" justify="start" className={termRowStyle}>
                  <Col className={termNameStyle}>{item.title}</Col>
                  <Col className={termDescriptionStyle}>{item.description}</Col>
                </Row>
              </HeaderRow>
              {item.terms && item.terms.map((term, termIndex) => (
                <>
                  <Row type="flex" justify="start" className={termRowStyle} style={{ backgroundColor: termIndex % 2 !== 0 ? '#f0f0f0' : 'white' }}>
                    <Col className={termNameStyle}>{term.name}</Col>
                    <Col className={termDescriptionStyle}>{term.description}</Col>
                  </Row>
                  {renderAddlRows(term)}
                </>
              ))}
            </div>
          ))}
        </div>
      </PageContent>
    );
  }
}
