import styled from '@emotion/styled';
import { Col, Row, Tag, Tooltip, Icon } from 'antd';
import { css, cx } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import { GithubStats } from './GithubStats';
import { trlToolip } from '../SearchRepos';

const title = css`
  font-weight: bold;
  font-size: 14px;
  a:hover {
    text-decoration: underline !important;
  }
`;

const doc = css`
  width: 100%;
  border: 1px solid #dcdcdc;
  box-shadow: rgb(0 0 0 / 30%) 1px 1px 2px;
  margin-bottom: 30px;
  padding: 9px;
  background: white;
  i {
    padding-right: 3px;
  }
`;

const tags = css`
  padding-top: 5px;
  span {
    margin-top: 5px;
  }
`;

const PrimaryUse = styled('div')`
  padding-bottom: 5px;
  padding-top: 10px;
  span {
    font-weight: bold;
  }
`;

const SmallInfo = styled('div')`
  padding-bottom: 5px;
  padding-top: 5px;
  span {
    font-size: 12px; 
    padding-right: 10px;
  }
`;


export default class RepoHitResult extends React.Component {
  static propTypes = {
    result: PropTypes.object,
  };

  renderTags = (items, color, tooltip) => {
    return items ? (
      <Tooltip title={tooltip}>
        {items.map(item => {
          return <Tag color={color} key={item}>{item}</Tag>;
        })}
      </Tooltip>
    ) : null;
  }

  renderMESStat = (stat, icon, tooltip) => {
    if (stat === null || stat === undefined) return null;

    const statSpan =(
      <span>
        {icon ? (<i className={icon}></i>) : null}
        {Array.isArray(stat) ? stat.join(', ') : stat}
      </span>);

    
    const toReturn = tooltip ? (
      <Tooltip title={tooltip}>
        {statSpan}
      </Tooltip>
    ) : statSpan;
    return toReturn;
  }

  render() {
    const { result } = this.props;
    const { _source } = result;
    const github = 'https://github.com';
    const isGitHub = _source.web_address.startsWith(github);

    const primaryUse =
      _source.primary_use ? (
          <PrimaryUse>
            <span>Primary Use: </span>
            {_source.primary_use.join(', ')}
          </PrimaryUse>
      ) : null;

    const stat = _source.open_source_license ? `${_source.open_source_license.join(', ')} (Open Source)` : null
    const openSourceLicense = this.renderMESStat(stat, "fa fa-lock-open");

    let logoImg = _source.logo_url ? 
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <img style={{ maxWidth: 270, maxHeight: 150 }} src={_source.logo_url}/>
      </div> :  null;

    // const leftColSpan = logoImg !== null | isGitHub ? 15 : 24;
    const leftColSpan = isGitHub ? 12 : logoImg !== null ? 15 : 24;

    const getTrlLabel = trl => trl === null ? null : (Array.isArray(trl) && trl.length === 3 ? "1-9" : trl);

    let document = (
      <div className={doc}>
        <Row
          gutter={[12, 8]}
          type="flex"
          justify="space-between"
        >
          <Col span={leftColSpan}>
              <div>
                <a className={title} href={_source.web_address} target="_blank">
                  {_source.title}
                  <i
                    className={cx(
                      'fa fa-external-link',
                      css`
                        margin-left: 5px;
                      `
                    )}
                    aria-hidden="true"
                  />
                </a>
              </div>

              {primaryUse}
              { (openSourceLicense || _source.other_license || _source.funding) &&
              <SmallInfo>
                {openSourceLicense}
                {this.renderMESStat(_source.other_license, "fa fa-lock")}
                {this.renderMESStat(_source.funding, "fa fa-building-columns")}
              </SmallInfo>
              }
              <SmallInfo>
                {this.renderMESStat(_source.cost, "fa fa-dollar-sign")}
                {this.renderMESStat(getTrlLabel(_source.trl), "fa fa-gear", trlToolip)}
              </SmallInfo>
              <SmallInfo>{_source.description}</SmallInfo>
              <div className={tags}>
                {this.renderTags(_source.programming_language, "#CFE9F8", "Languages")}
                {this.renderTags(_source.life_cycle, "#CCE8E3", "Life Cycle Stage")}
                {this.renderTags(_source.collection_method, "#F0D0EF", "Collection Method")}
                {this.renderTags(_source.discipline, "#FFE7CC", "Discipline")}
                {this.renderTags(_source.technology, "#E5E8EC", "Technology")}
              </div>
          </Col>
          {isGitHub ? 
            <Col span={12} style={{borderLeft: "1px solid #dcdcdc"}}>
              <GithubStats _source={_source}/>
            </Col>
          : logoImg ? 
            <Col span={9}>
              {logoImg}
            </Col> : null
          }
        </Row>
      </div>
    );
    return document;
  }
}
