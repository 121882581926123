import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';


const NavText = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-left: 5px;
  color: white;
  &:hover {
    color: #ddd;
  }
  padding-right: 10px;
`;


export interface INavItemProps {
  displayTitle: string;
  to?: string;
  url?: string;
}

export default class NavItem extends React.Component<INavItemProps> {
  render() {
    return this.props.to ? (<Link to={this.props.to}>
      <NavText>{this.props.displayTitle}</NavText>
    </Link>)
      :
      (<a href={this.props.url} target="_blank">
        <NavText>{this.props.displayTitle}</NavText>
      </a>
      );
  }
}


