import styled from '@emotion/styled';
import { Icon, Input } from 'antd';
import FeaturedSoftwareBody from 'components/page/home/FeaturedSoftwareBody';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import background from 'resources/Group20.png';
import squiggle from 'resources/squiggle.png';
import devGuideImage from 'resources/footer_logos/102px-Telesto_Logo.png';
import dataRepoImage from 'resources/footer_logos/129px-MHK-logo.png';
import techDocsImage from 'resources/footer_logos/129px-TethysEngineering.png';
import evnDocsImage from 'resources/footer_logos/148px-Tethys-Full-Logo.png';
import projectsDatabaseImage from 'resources/footer_logos/62px-Projects_Database_Logo.png';
import geospatialDataImage from 'resources/footer_logos/64px-MEAtlas-Logo-Untitled.png';
import mrePortalImage from 'resources/footer_logos/PRIMRE_Logo.png';
import theme from 'services/Theme';

import { Col, Row, Typography } from 'antd';
const { Search } = Input;
const { Title } = Typography;

const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageBanner = styled.div`
  position: relative;
  display: inline-block;
`;

const HeaderBackgroundImg = styled.img`
  width: 1300px; 
  height: 180px;
`;

const FooterBackgroundImg = styled.img`
  width: 1300px; 
  height: 120px;
`;

const ImageText = styled.p`
    color: white;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    background-color:rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const FeaturedSoftware = styled.div`
  width: 800px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeaturedSoftwareHeader = styled.div`
  /* height: 25px; */
  display: flex;
  width: 100%;
  color: ${theme.colors.primary};
  font-weight: 900;
  /* margin-top: 20px; */
  /* padding-left: 20px; */
`;

const SoftwareLinks = styled.div`  
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
`;

const Capabilities = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  /* all child divs have marginBottom of 10px */
  & > * {
    margin-bottom: 20px;
  }
`;

const FooterRow = styled.div`
  & a, & h1, & h2, & h3, & h4, & h5, & h6 {
    color: black;
  }
  background-color: #DCF0F9;
  width: 1300px;
  margin-top: 30px;
  padding-bottom: 50px;
  & > * {
    padding-bottom: 20px;
  }
`;

const LogoTitle = styled.div`
  padding-top: 5px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
`;

const LogoImg = styled.img`
  height: 30px;
`;

const ResouceLink = styled.a`
  display: flex; 
  flex-direction: column;
  align-items: center;
  width: 160px;
  margin-right: 10px;
`;

export interface IHomeProps extends RouteComponentProps {
}

class Home extends React.Component<IHomeProps> {
  handleSearch = (value) => {
    this.props.history.push(`/searchRepos?q=${value}`);
  }

  render() {
    return (
      <PageContent>
        <ImageBanner>
          <HeaderBackgroundImg src={background} alt="background image" />
          <ImageText>
            A collection of software relevant to marine energy development
          </ImageText>
        </ImageBanner>

        <Capabilities>
          <FeaturedSoftwareHeader>Search Software</FeaturedSoftwareHeader>

          <Search
            style={{ width: '350px' }}
            placeholder="Search..."
            enterButton="Search"
            size="large"
            onSearch={this.handleSearch}
          />
          <SoftwareLinks>
            <Link to="/searchRepos">
              <Icon type="read" /> Browse Software
            </Link>
            <a href="https://github.com/MRE-Code-Hub/Marine_Energy_Software_KH/blob/main/CONTRIBUTING.md" target="_blank">
              <Icon type="cloud-upload" /> Register Software
            </a>
          </SoftwareLinks>
        </Capabilities>

        <FeaturedSoftware>
          <FeaturedSoftwareHeader>New Open Source Releases</FeaturedSoftwareHeader>
          <FeaturedSoftwareBody />
        </FeaturedSoftware>
        <FooterRow>
          
          <ImageBanner>
            <FooterBackgroundImg src={squiggle} alt="squiggle image" />
          </ImageBanner>
          <Row type="flex" justify="center">
            <Title level={4}>More Marine Energy resources</Title>
          </Row>
          <Row type="flex" justify="center">
            <ResouceLink href="https://openei.org/wiki/PRIMRE" target="_blank">
              <img style={{height: '40px'}} src={mrePortalImage} alt="DOE Logo" />
              <LogoTitle></LogoTitle>
            </ResouceLink>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <ResouceLink href="https://mhkdr.openei.org/" target="_blank">
                <LogoImg src={dataRepoImage} alt="openei Logo" />
                <LogoTitle>Data Repository</LogoTitle>
              </ResouceLink>
            </Col>
            <Col>
              <ResouceLink href="https://tethys.pnnl.gov/" target="_blank">
                <LogoImg src={evnDocsImage} alt="tethys Logo" />
                <LogoTitle>Environmental Documents</LogoTitle>
              </ResouceLink>
            </Col>
            <Col>
              <ResouceLink href="https://tethys-engineering.pnnl.gov/" target="_blank">
                <LogoImg src={techDocsImage} alt="tethys engineering Logo" />
                <LogoTitle>Technical Documents</LogoTitle>
              </ResouceLink>
            </Col>
            <Col>
              <ResouceLink href="https://openei.org/wiki/PRIMRE/Databases/Projects_Database" target="_blank">
                <LogoImg src={projectsDatabaseImage} alt="PREIMRE projects database Logo" />
                <LogoTitle>Projects Database</LogoTitle>
              </ResouceLink>
            </Col>
            <Col>
              <ResouceLink href="https://openei.org/wiki/PRIMRE/Telesto" target="_blank">
                <LogoImg src={devGuideImage} alt="PREIMRE Telesto Logo" />
                <LogoTitle>Development Guidance</LogoTitle>
              </ResouceLink>
            </Col>
            <Col>
              <ResouceLink href="https://maps.nrel.gov/marine-energy-atlas" target="_blank">
                <LogoImg src={geospatialDataImage} alt="marine energy atlas Logo" />
                <LogoTitle>Geospatial Data</LogoTitle>
              </ResouceLink>
            </Col>
          </Row>
        </FooterRow>
      </PageContent>

    );
  }
}

export default withRouter(Home);