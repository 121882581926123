import React from 'react';
import PropTypes from 'prop-types';
import { SearchkitManager } from 'searchkit';
import _ from 'lodash';

export default class PcmSearchkitManager extends SearchkitManager {


    /**
     * We have to override Searchkit's version to get the hits from the 'total.value', which changed for ES 7.
     * @returns {number}
     */
    getHitsCount() {
        return _.get(this.results, ['hits', 'total', 'value'], 0);
    }

    hasHits() {
        return this.getHitsCount() > 0;
    }

}
